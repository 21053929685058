@import '../../styles/mixins.less';

.device-device-realtime-dashboard {
    box-sizing: border-box;
    padding: @bigPad;

    .board{
        box-sizing: border-box;
        // display: flex;
        // flex-wrap: wrap;
        margin-bottom: 50px;
        margin-left: -@widgetMargin/2;
    }
    .minimized{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      > * {
        &.label{
          margin-right: 20px;
          color: var(--color-text-main-lt);
          cursor: default;
        }
      }
      .device-widget{
        height: 40px !important;
        width: 40px;
        margin: 0 15px 0 0;
        background-color: transparent;
        box-shadow: none;
        display: block;
        position: relative;
        .device-webcam-image {
          display: none;
        }
        &.webcam {
          .device-webcam-image {
            display: none;
          }
        }
        > div{
          display: none;
        }
        header{
          border: none;
          input{
            display: none;
          }
          &:after{
            left: 0;
            top: 0;
            bottom: auto;
          }
          &:before{
            left: auto;
            right: -5px;
            bottom: -36px;
          }
        }
      }
    }
    .device-widget{
      &.stats{
        a:first-of-type{
          display: block;
          color: #000;
        }
        .wrap{
          padding: @bigPad;
        }
      }
    }
}
.app.v3{
  .device-device-realtime-dashboard{
    padding-top: 0;
  }
}

@media (max-width: @phone-max) {
  .device-device-realtime-dashboard {
    padding-top: 0px;
    .board{
      margin-left: 0px;
      margin-bottom: 0;
    }
    .minimized{
      .label{
        margin-right: 0px;
        width: 100%;
        font-size: 1.7rem;
        text-align: center;
      }
      > div {
        margin-bottom: 15px;
      }
      .device-widget{
        height: 60px !important;
        width: 60px !important;
        margin: 3px 3px;
        header{
          &:before{
            bottom: -58px;
          }
        }
      }
    }
  }
  .app.v3{
    .device-device-realtime-dashboard {
      > div > .minimized{
        margin-bottom: 20px;
      }
    }
  }
}
